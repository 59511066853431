/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useObserver } from '@/hooks';

import { Container, Wrapper, Text, Link } from './styles';

const Agreement = () => {
  const { contentJson } = useStaticQuery(
    graphql`
      query {
        contentJson(agreement: { id: { eq: "agreement" } }) {
          agreement {
            content {
              links {
                href
                name
              }
              texts
            }
          }
        }
      }
    `
  );
  const { setPosition } = useObserver();

  return (
    <Container>
      {contentJson.agreement.content.map(({ texts, links }, index) => (
        <Wrapper key={index} onTouchStart={setPosition} onMouseEnter={setPosition}>
          {texts && texts.map((text, key) => <Text key={key}>{text}</Text>)}
          {links &&
            links.map(({ name, href }, key) => (
              <Fragment key={key}>
                <Link key={key} href={href} target="_blank" rel="noreferrer nofollow">
                  {name}
                </Link>
                <br />
              </Fragment>
            ))}
        </Wrapper>
      ))}
    </Container>
  );
};

export default Agreement;
